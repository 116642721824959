@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Cirka;
  src: url('../static/fonts/Cirka-Regular.woff2') format('woff2'),
    url('../static/fonts/Cirka-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Neue Montreal;
  src: url('../static/fonts/PPNeueMontreal-Book.woff2') format('woff2'),
    url('../static/fonts/PPNeueMontreal-Book.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

/* COLORS */
:root {
  --size: 375;

  --color-grid: #00f;
  --color-black: #000;
  --color-white: #fff;
  --color-blue: #00f;
  --color-primary: 1, 0, 55;
  --color-secondary: 0, 36, 163;

  --ease-out: cubic-bezier(0.19, 1, 0.22, 1);

  @screen md {
    --size: 768;
  }

  @screen lg {
    --size: 1024;
  }

  @screen xl {
    --size: 1440;
  }
}

@layer components {
  .body-9 {
    @apply text-9;
  }

  .body-10 {
    @apply text-10 leading-[100%];
  }

  .body-12 {
    @apply text-12;
  }

  .body-18 {
    @apply text-18;
  }

  .heading-18 {
    @apply font-heading text-18;
  }

  .heading-30 {
    @apply font-heading text-30 leading-[100%];
  }
}

html {
  font-family: Arial, sans-serif;
  font-size: calc(10 * (100vw / var(--size)));
  
  @apply select-none;
}

body {
  -webkit-touch-callout: none !important;

  @apply w-screen h-screen font-body leading-[133.333%];
}

.char {
  @apply inline-block;
}

.svg-wrapper {
  svg {
    @apply w-full h-auto;
  }
}

#gl {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.intro-inner {
  box-shadow: 0px 0px 80px 0px rgba(0, 0, 255, 0.70) inset;
  background-color: #010037;
}

.intro-title {
  animation: marquee infinite 10s linear running;
}

.intro-start-xp {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 1;
  padding: 10px;
}

.tp-dfwv {
  position: fixed !important;
  top: initial !important;
  right: initial !important;
  bottom: 8px;
  left: 8px;
}

input[type="color"] {
	-webkit-appearance: none;

  @apply border-none;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  @apply p-0;
}

input[type="color"]::-webkit-color-swatch {
  @apply border-none rounded-full;
}

.circle-left,
.circle-right,
.restart-circle-left,
.restart-circle-right {
  stroke-dasharray: 332;
}

.restart-circle-left,
.restart-circle-right {
  stroke-dashoffset: 332;
  transition: stroke-dashoffset 1s var(--ease-out);
}

.restart-button:hover .restart-circle-left,
.restart-button:hover .restart-circle-right {
  stroke-dashoffset: 0;
}

.about-button:hover + .about-bg {
  @apply xl:border-active;
}

.custom-button:hover + .custom-bg {
  @apply xl:border-active;
}

.audio path {
  animation: audio 1.4s infinite running linear forwards;
  transform-origin: bottom;
}

.audio.muted path {
  animation: none;
  transform: scaleY(0.3);
}

.audio path:nth-child(1) {
  animation-delay: 0s;
}

.audio path:nth-child(2) {
  animation-delay: 0.2s;
}

.audio path:nth-child(3) {
  animation-delay: 0.4s;
}

.audio path:nth-child(4) {
  animation-delay: 0.6s;
}

.audio path:nth-child(5) {
  animation-delay: 0.8s;
}


select {
  outline: none;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-33.333%);
  }
}


@keyframes audio {
  0%, 100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0.3);
  }
}

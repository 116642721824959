*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.body-9 {
  font-size: .9rem;
}

.body-10 {
  font-size: 1rem;
  line-height: 100%;
}

.body-12 {
  font-size: 1.2rem;
}

.heading-18 {
  font-family: Cirka, sans-serif;
  font-size: 1.8rem;
}

.heading-30 {
  font-family: Cirka, sans-serif;
  font-size: 3rem;
  line-height: 100%;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-left-\[45rem\] {
  left: -45rem;
}

.bottom-20 {
  bottom: 2rem;
}

.bottom-\[10rem\] {
  bottom: 10rem;
}

.bottom-\[4rem\] {
  bottom: 4rem;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-20 {
  left: 2rem;
}

.right-0 {
  right: 0;
}

.right-10 {
  right: 1rem;
}

.right-20 {
  right: 2rem;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-20 {
  top: 2rem;
}

.top-\[5rem\] {
  top: 5rem;
}

.top-full {
  top: 100%;
}

.z-1 {
  z-index: 1;
}

.z-10 {
  z-index: 10;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-\[21\] {
  z-index: 21;
}

.z-intro {
  z-index: 20;
}

.z-overlay {
  z-index: 15;
}

.mx-\[2rem\] {
  margin-left: 2rem;
  margin-right: 2rem;
}

.-mb-\[2rem\] {
  margin-bottom: -2rem;
}

.mb-10 {
  margin-bottom: 1rem;
}

.mb-\[0\.5rem\] {
  margin-bottom: .5rem;
}

.mb-\[1\.1rem\] {
  margin-bottom: 1.1rem;
}

.mb-\[3rem\] {
  margin-bottom: 3rem;
}

.mb-\[7rem\] {
  margin-bottom: 7rem;
}

.mt-\[10rem\] {
  margin-top: 10rem;
}

.mt-\[2\.8rem\] {
  margin-top: 2.8rem;
}

.mt-\[3rem\] {
  margin-top: 3rem;
}

.mt-\[5\.7rem\] {
  margin-top: 5.7rem;
}

.mt-\[5rem\] {
  margin-top: 5rem;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-\[1\.2rem\] {
  height: 1.2rem;
}

.h-\[18\.2rem\] {
  height: 18.2rem;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[22\.5rem\] {
  height: 22.5rem;
}

.h-\[3\.4rem\] {
  height: 3.4rem;
}

.h-\[3rem\] {
  height: 3rem;
}

.h-\[calc\(100\%-4px\)\] {
  height: calc(100% - 4px);
}

.h-full {
  height: 100%;
}

.w-\[0\.7rem\] {
  width: .7rem;
}

.w-\[1\.1rem\] {
  width: 1.1rem;
}

.w-\[1\.2rem\] {
  width: 1.2rem;
}

.w-\[1\.5rem\] {
  width: 1.5rem;
}

.w-\[14\.5rem\] {
  width: 14.5rem;
}

.w-\[16\.5rem\] {
  width: 16.5rem;
}

.w-\[18\.2rem\] {
  width: 18.2rem;
}

.w-\[22\.5rem\] {
  width: 22.5rem;
}

.w-\[3\.4rem\] {
  width: 3.4rem;
}

.w-\[30vw\] {
  width: 30vw;
}

.w-\[3rem\] {
  width: 3rem;
}

.w-\[8\.6rem\] {
  width: 8.6rem;
}

.w-\[calc\(100\%-4px\)\] {
  width: calc(100% - 4px);
}

.w-\[calc\(100vw-4rem\)\] {
  width: calc(100vw - 4rem);
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.flex-1 {
  flex: 1;
}

.origin-left {
  transform-origin: 0;
}

.-translate-y-\[3\%\] {
  --tw-translate-y: -3%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-center {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-center {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-90 {
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.appearance-none {
  appearance: none;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-x-\[0\.5rem\] {
  column-gap: .5rem;
}

.gap-x-\[1rem\] {
  column-gap: 1rem;
}

.gap-x-\[2rem\] {
  column-gap: 2rem;
}

.overflow-hidden {
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-15 {
  border-radius: 1.5rem;
}

.rounded-\[0\.3rem\] {
  border-radius: .3rem;
}

.rounded-\[0\.4rem\] {
  border-radius: .4rem;
}

.rounded-\[0\.5rem\] {
  border-radius: .5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.border-px {
  border-width: 1px;
}

.border-none {
  border-style: none;
}

.border-blue {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 255 / var(--tw-border-opacity));
}

.border-grey {
  border-color: #ffffff4d;
}

.bg-\[rgba\(var\(--color-primary\)\,0\.9\)\] {
  background-color: rgba(var(--color-primary), .9);
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue {
  background-color: var(--color-blue);
}

.bg-primary {
  background-color: rgb(var(--color-primary));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gradient {
  background-image: radial-gradient(31.25% 50% at 50% 50%, rgba(var(--color-secondary), .4) 0%, #0000 100%);
}

.bg-gradient-restart {
  background-image: radial-gradient(31.25% 50% at 50% 50%, rgba(var(--color-secondary), .3) 0%, rgba(var(--color-secondary), 0) 100%);
}

.p-20 {
  padding: 2rem;
}

.px-10 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-\[1\.2rem\] {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

.py-\[1\.2rem\] {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.py-\[1\.5rem\] {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-\[0\.9rem\] {
  padding-bottom: .9rem;
}

.pr-10 {
  padding-right: 1rem;
}

.pt-10 {
  padding-top: 1rem;
}

.text-center {
  text-align: center;
}

.font-heading {
  font-family: Cirka, sans-serif;
}

.text-\[8rem\] {
  font-size: 8rem;
}

.uppercase {
  text-transform: uppercase;
}

.leading-\[10rem\] {
  line-height: 10rem;
}

.leading-\[1rem\] {
  line-height: 1rem;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue {
  color: var(--color-blue);
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-30 {
  opacity: .3;
}

.transition-border {
  transition-property: border-color;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-1000 {
  transition-duration: 1s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-700 {
  transition-duration: .7s;
}

.ease-out {
  transition-timing-function: var(--ease-out);
}

.will-change-transform {
  will-change: transform;
}

@font-face {
  font-family: Cirka;
  src: url("Cirka-Regular.6249c51e.woff2") format("woff2"), url("Cirka-Regular.e4891296.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Neue Montreal;
  src: url("PPNeueMontreal-Book.95759468.woff2") format("woff2"), url("PPNeueMontreal-Book.a45fde03.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}

:root {
  --size: 375;
  --color-grid: #00f;
  --color-black: #000;
  --color-white: #fff;
  --color-blue: #00f;
  --color-primary: 1, 0, 55;
  --color-secondary: 0, 36, 163;
  --ease-out: cubic-bezier(.19, 1, .22, 1);
}

@media (width >= 768px) {
  :root {
    --size: 768;
  }
}

@media (width >= 1024px) {
  :root {
    --size: 1024;
  }
}

@media (width >= 1200px) {
  :root {
    --size: 1440;
  }
}

html {
  font-family: Arial, sans-serif;
  font-size: calc(10 * (100vw / var(--size)));
  -webkit-user-select: none;
  user-select: none;
}

body {
  width: 100vw;
  height: 100vh;
  font-family: Neue Montreal, sans-serif;
  line-height: 133.333%;
  -webkit-touch-callout: none !important;
}

.char {
  display: inline-block;
}

.svg-wrapper svg {
  width: 100%;
  height: auto;
}

#gl {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.intro-inner {
  background-color: #010037;
  box-shadow: inset 0 0 80px #0000ffb3;
}

.intro-title {
  animation: 10s linear infinite marquee;
}

.intro-start-xp {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 1;
  padding: 10px;
}

.tp-dfwv {
  bottom: 8px;
  left: 8px;
  top: initial !important;
  right: initial !important;
  position: fixed !important;
}

input[type="color"] {
  -webkit-appearance: none;
  border-style: none;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border-style: none;
  border-radius: 9999px;
}

.circle-left, .circle-right, .restart-circle-left, .restart-circle-right {
  stroke-dasharray: 332;
}

.restart-circle-left, .restart-circle-right {
  stroke-dashoffset: 332px;
  transition: stroke-dashoffset 1s var(--ease-out);
}

.restart-button:hover .restart-circle-left, .restart-button:hover .restart-circle-right {
  stroke-dashoffset: 0;
}

@media (width >= 1200px) {
  .about-button:hover + .about-bg, .custom-button:hover + .custom-bg {
    border-color: #ffffffb3;
  }
}

.audio path {
  transform-origin: bottom;
  animation: 1.4s linear infinite forwards audio;
}

.audio.muted path {
  animation: none;
  transform: scaleY(.3);
}

.audio path:first-child {
  animation-delay: 0s;
}

.audio path:nth-child(2) {
  animation-delay: .2s;
}

.audio path:nth-child(3) {
  animation-delay: .4s;
}

.audio path:nth-child(4) {
  animation-delay: .6s;
}

.audio path:nth-child(5) {
  animation-delay: .8s;
}

select {
  outline: none;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-33.333%);
  }
}

@keyframes audio {
  0%, 100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(.3);
  }
}

.group\/copy.a .group-\[\.a\]\/copy\:-translate-y-full, .group.a .group-\[\.a\]\:-translate-y-full {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group.a .group-\[\.a\]\:translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group.a .group-\[\.a\]\:scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group.a .group-\[\.a\]\:text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

@media (width >= 768px) {
  .md\:-left-\[90rem\] {
    left: -90rem;
  }

  .md\:mx-\[3rem\] {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .md\:-mb-\[0\.5rem\] {
    margin-bottom: -.5rem;
  }

  .md\:-mb-\[3rem\] {
    margin-bottom: -3rem;
  }

  .md\:mb-\[10rem\] {
    margin-bottom: 10rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:h-\[5rem\] {
    height: 5rem;
  }

  .md\:w-\[20\.4rem\] {
    width: 20.4rem;
  }

  .md\:w-\[20vw\] {
    width: 20vw;
  }

  .md\:w-\[30rem\] {
    width: 30rem;
  }

  .md\:w-\[39rem\] {
    width: 39rem;
  }

  .md\:w-\[5rem\] {
    width: 5rem;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:text-\[17rem\] {
    font-size: 17rem;
  }

  .md\:leading-\[20rem\] {
    line-height: 20rem;
  }
}

@media (width >= 1024px) {
  .lg\:w-\[13\.4rem\] {
    width: 13.4rem;
  }
}

@media (width >= 1200px) {
  .xl\:block {
    display: block;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:hover\:border-active:hover {
    border-color: #ffffffb3 !important;
  }

  .xl\:hover\:border-blue-active:hover {
    --tw-border-opacity: 1;
    border-color: rgb(0 0 255 / var(--tw-border-opacity));
  }

  .xl\:hover\:opacity-60:hover {
    opacity: .6;
  }

  .xl\:hover\:opacity-70:hover {
    opacity: .7;
  }

  .xl\:\[\&\:not\(\.a\)\]\:hover\:opacity-75:hover:not(.a) {
    opacity: .75;
  }
}

/*# sourceMappingURL=index.0ea6436d.css.map */
